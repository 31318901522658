@import '~bootstrap/scss/bootstrap';
@import "@angular/material/prebuilt-themes/purple-green.css";

*,
*::before,
*::after {
  box-sizing: content-box;
}

button {
  box-sizing: border-box;
}

img {
  vertical-align: baseline;
  border-style: none;
}

body {
  background: #0b0e12;
  font-family: Poppins, serif;
  overflow-x: hidden;
  &.land {
    background-image: url(../src/assets/images/elseverse/BG-stars.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    app-header {
      margin-bottom: 25px;
      background: #0b0e12;
    }
    .footerTop {
      .footerTopLeft {
        .logo {
          a {
            img {
              width: 107px;
              height: 40px;
            }
          }
        }
        form {
          button {
            background-color: #00f0ff;
          }
        }
      }
    }
    .nft-card {
      .nft-card-left {
        .time-left {
          @media only screen and (max-width: 1200px) and (min-width: 1000px)  {
            bottom: 130px !important;
          }
        }
        .item_nft_img {
          border: 1px solid #535C71;
        }
      }
      .two-sections {
        .bid-info {
          .action-btn {
            background-color: #00f0ff;
          }
        }
      }
    }
    .lottery-info {
      .wrapper {
        box-sizing: border-box;
        flex-grow: 1;
        @media screen and (max-width: 1000px) {
          max-width: 500px;
          width: 100% !important;
          h3 {
            text-align: center;
          }
          .filter {
            .mat-radio-group {
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
      @media screen and (max-width: 1000px) {
        flex-direction: column;
        align-items: center;
        gap: 20px;
      }
    }
    .more-collection {
      .border-href {
        &::after {
          background: linear-gradient(90deg, #007880 0%, #00F0FF 100%);
        }
        &:hover {
          &::after {
            content: "";
            background: linear-gradient(90deg, #007880 20%, #00F0FF 70%);
            transition: 1.5s;
          }
          a {
            background: linear-gradient(90deg, #007880 20%, #00F0FF 70%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            transition: 0.5s;
          }
        }
        a {
          background: linear-gradient(90deg, #007880 0%, #00F0FF 100%);
          -webkit-background-clip: text;
        }
      }
    }
    #choose-method-modal {
      .jw-modal {
        .jw-modal-body {
          .buttons {
            .buy {
              background-color: #00F0FF;
            }
          }
          .buy {
            background-color: #00F0FF;
          }
        }
      }
    }
    #enter-wallet-modal {
      .jw-modal {
        .jw-modal-body {
          .buttons {
            .buy {
              background-color: #00F0FF;
            }
          }
        }
      }
    }
    #connect-wallet {
      .jw-modal {
        .jw-modal-body {
          .wallet-btn {
            button {
              background-color: #00F0FF;
            }
          }
        }
      }
    }
  }
}

html {
  overflow-x: hidden;
}

h2 {
  font-size: 40px;
  line-height: 100%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ffffff;
  font-family: "Syncopate", sans-serif;
  margin: 0;
}

h5 {
  font-family: "Druk Text Wide", serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 100%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #fff;
  margin: 0;
}

.container {
  max-width: 1520px;
  margin: 0 auto;
  padding: 0;
}

header .container {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding-top: 40px;
  padding-bottom: 20px;
  align-items: center;
  margin-bottom: 30px;
}

.btn-h {
  display: flex;
  padding: 17px 31px;
  color: #f2f2f2;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  line-height: 100%;
  text-decoration: none;
  border: 1px solid #353e51;
  border-radius: 10px;
  transition: 0.5s;
  background-color: transparent;
}

.btn-h:hover {
  border: 1px solid #fff;
  opacity: 0.6;
  color: #fff;
  transition: 0.5s;
}

.btn-h.connect-wallet::before {
  content: "";
  width: 20px;
  height: 21px;
  background: url(/assets/images/wallet.png);
  display: block;
  background-size: contain;
  margin-right: 10px;
  margin-top: -5px;
}

.nft-home-btn a:hover {
  border: 1px solid #fff;
  opacity: 0.6;
  color: #fff;
  transition: 0.5s;
}

.head-menu ul {
  margin: 0;
  padding: 0;
  display: flex;
  gap: 27px;
  list-style: none;
}

.head-menu a {
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: #828282;
  text-decoration: none;
  transition: 0.5s;
}

.head-menu a:hover {
  color: #fff;
  transition: 0.5s;
  text-decoration: revert;
}

.head-menu a.activ {
  color: #fff;
}

.three-block .container {
  grid-template-areas: "A B" "A C";
  display: grid;
  gap: 20px;
  grid-template-columns: 650px 850px;
  &.two-cols {
    grid-template-areas: "A B";
    @media screen and (max-width: 1520px) {
      grid-template-columns: 1fr;
      grid-template-areas: "A" "B";
    }
  }
}

.three-block1 {
  grid-area: A;
  background: #121922;
  border-radius: 20px;
  padding: 44px 57px;
  position: relative;
  overflow: hidden;
  @media screen and (max-width: 500px) {
    min-height: 300px;
  }
}

.three-block2 {
  grid-area: B;
  background: #121922;
  border-radius: 20px;
  padding: 37px 43px;
  position: relative;
  height: 266px;
  overflow: hidden;
}

.three-block3 {
  grid-area: C;
  background: #121922;
  border-radius: 20px;
  padding: 37px 43px;
  position: relative;
  height: 266px;
  overflow: hidden;
}

.three-block a {
  background: linear-gradient(135deg, #12bcb0 20%, #fabe0e 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 16px;
  line-height: 100%;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.03em;
  position: relative;
  z-index: 1;
  padding: 17px 59px;
  //font-family: 'Manrope';
}

.three-block .border-href {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.three-block .border-href:after {
  content: "";
  background: linear-gradient(135deg, #12bcb0 20%, #fabe0e 70%);
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  border-radius: 10px;
  z-index: 0;
  transition: 1.5s;
}

.three-block .border-href:before {
  content: "";
  background: #1c2437;
  position: absolute;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  left: 1px;
  border-radius: 10px;
  z-index: 1;
  padding: 1px;
  margin: 0;
}

.three-block .container > div:not(.special-events) img {
  position: absolute;
  bottom: 0;
  transition: 0.5s;
  right: 0;
}

.three-block .container > div.three-block1 img {
  position: absolute;
  bottom: 0;
  transition: 0.5s;
  left: 0;
  width: 100%;
}

.three-block .container h3 {
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin: 0;
  font-family: "Syncopate", sans-serif;
  margin-bottom: 13px;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 130%;
  color: #d9e9ff;
}

.three-block .three-block2 h3 {
  -webkit-background-clip: text;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 118%;
  color: #d9e9ff;
}

.three-block .three-block3 h3 {
  -webkit-background-clip: text;
  max-width: 435px;
  font-weight: 700;
  font-size: 24px;
  line-height: 118%;
  color: #d9e9ff;
}

.three-block1:hover img,
.three-block2:hover img,
.three-block3:hover img {
  transition: 0.5s;
  filter: brightness(0.8);
}

.three-block .container p {
  max-width: 380px;
  margin-bottom: 30px;
  font-family: "Poppins", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 172%;
  color: #d9e9ff;
}

.three-block {
  margin-bottom: 100px;
}

.border-href:hover:after {
  content: "";
  background: linear-gradient(122deg, #fabe0e 20%, #12bcb0 70%);
  transition: 1.5s;
}

.border-href:hover a {
  background: linear-gradient(122deg, #fabe0e 20%, #12bcb0 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: 0.5s;
}

.nft-home {
  margin-bottom: 100px;
}

.nft-home-top {
  display: flex;
  align-items: center;
  gap: 68px;
  margin-bottom: 50px;
}

.nft-home-btn {
  display: flex;
  gap: 18px;
}

.nft-home-btn a {
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #ffffff;
  text-decoration: none;
  font-weight: bold;
  font-size: 12px;
  line-height: 100%;
  padding: 12px 42px;
  border: 1px solid #828282;
  box-sizing: border-box;
  border-radius: 10px;
}

.nft-home-card {
  justify-content: center;
  row-gap: 20px;
  display: grid;
  grid-template-columns: 2fr 2fr;
  gap: 20px;
  @media screen and (max-width: 970px) {
    grid-template-columns: 2fr;
  }
}

.item_nft {
  position: relative;
  border-radius: 19px;
  overflow: hidden;
  .showActionButtons {
    display: grid;
    gap: 8px;
    position: absolute;
    bottom: 0;
    padding-top: 0;
    width: calc(100% - 48px);
    margin: 24px;
    button {
      height: 56px;
      background: #ffb800;
      border-radius: 12px;
      border: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #121922;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
    }
  }
  .showSellButtons a {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #d9e9ff;
    cursor: pointer;
    background: rgba(53, 62, 81, 0.4);
    border: 1px solid #535c71;
    border-radius: 12px;
    padding: 16px;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: left;
    position: relative;
  }
}

.mat-menu-putToSell {
  max-width: 342px !important;
  button {
    width: 342px !important;
    justify-content: left;
    padding-left: 17px !important;
    @media screen and (max-width: 1520px) {
      max-width: 262px !important;
      width: 260px !important;
    }
    @media screen and (max-width: 500px) {
      max-width: 232px !important;
      width: 230px !important;
    }
  }
  @media screen and (max-width: 1520px) {
    max-width: 262px !important;
  }
  @media screen and (max-width: 500px) {
    max-width: 262px !important;
  }
}

.showSellButtons .action-btn a:after {
  content: "❮";
  transform: rotate(-90deg) scale(0.55);
  font-family: icomoon, serif;
  font-size: 24px;
  line-height: 1;
  position: absolute;
  right: 22px;
  top: 14px;
  color: #d9e9ff;
}

.filterWalletNft {
  border-bottom: 1px solid #535c71;
  display: flex;
  gap: 32px;
  margin-bottom: 50px;
  button {
    border: 0;
    border-bottom: 1px solid #535c71;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: flex-end;
    text-align: center;
    color: #d9e9ff;
    background: none;
    padding: 16px 0;
    margin-bottom: -1px;
  }
  .active {
    border-bottom: 1px solid #0cfdff;
  }
}

.search input {
  border: 1px solid #353e51;
  box-sizing: border-box;
  border-radius: 10px;
  background: none;
  color: #fff;
  width: 365px;
  height: 36px;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: 0.03em;
  padding-left: 20px;
}

.search {
  position: relative;
}

.search button {
  background: none;
  border: 0;
  position: absolute;
  right: 8px;
  top: 7px;
}

.filter_btn {
  border: 1px solid #353e51;
  border-radius: 10px;
  overflow: hidden;
  height: 36px;
  display: flex;
  align-items: center;
}

.filter_btn button {
  background: none;
  border: 0;
  color: #fff;
  padding: 0 15px;
}

.filter_btn button.act {
  background: #1c2437;
  border-radius: 10px;
  border: 0;
  height: 36px;
  color: #fff;
  padding: 0 15px;
}

.select_fil select {
  border: 1px solid #353e51;
  box-sizing: border-box;
  border-radius: 10px;
  background: none;
  color: #fff;
  padding-left: 10px;
  padding-right: 25px;
  margin-right: 10px;
  height: 36px;
}

.select_fil select option {
  color: #353e51;
}

.item_nft video {
  width: 365px;
  position: absolute;
  z-index: -1;
  top: 0;
}

.item_nft_content {
  width: 100%;
  height: 93px;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(48.3277px);
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 26px;
}

.btn {
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #f2f2f2;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  padding: 13px 23px;
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  transition: 0.5s;
  z-index: 5;
  border: 0;
  background: linear-gradient(135deg, #12bcb0 20%, #fabe0e 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}

.btn:hover {
  opacity: 0.6;
  transition: 0.5s;
}

.item_nft .t {
  font-size: 12px;
  line-height: 160%;
  color: #fff;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(48.3277px);
  display: flex;
  max-width: fit-content;
  gap: 7px;
  padding: 3px;
  padding-right: 7px;
  border-radius: 4px;
  position: absolute;
  top: 16px;
  left: 16px;
}

.item_nft_left h5 {
  margin-bottom: 10px;
  width: 180px;
  font-size: 14px;
  font-family: "Syncopate", sans-serif;
}

.item_nft_left div {
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #a7a7a7;
  align-items: center;
  display: flex;
  gap: 5px;
}

.footer-right form {
  position: relative;
  width: max-content;
}

.footer-right form input {
  background: #1c2437;
  border-radius: 10px;
  border: 0;
  width: 315px;
  height: 52px;
  padding-left: 22px;
  font-size: 11px;
  line-height: 16px;
  outline: none;
  color: #fff;
}

.footer-right form button {
  padding: 12px 15px;
  border-radius: 10px;
  outline: none;
  border: 0;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.065em;
  text-transform: capitalize;
  cursor: pointer;
  font-weight: 600;
  transition: 0.5s;
  background: linear-gradient(135deg, #12bcb0 20%, #fabe0e 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 12px;
  white-space: nowrap;
  z-index: 5;
  width: 128px;
  height: 42px;
}

.footer-right form button:hover {
  transition: 0.5s;
  opacity: 0.7;
}

.footer-right .menu_widget_t {
  font-weight: bold;
  font-size: 21.4035px;
  line-height: 32px;
  text-transform: capitalize;
  color: #ffffff;
  margin-bottom: 19px;
}

.menu_widget .menu_widget_t {
  font-weight: 600;
  font-size: 21.4035px;
  line-height: 32px;
  text-transform: capitalize;
  color: #ffffff;
  margin-bottom: 20px;
}

footer {
  background: #121622;
  padding-top: 28px;
  padding-bottom: 40px;
}

.cabinet_wallet_top_bg img {
  width: 100%;
  height: 170px;
}

.menu_widget ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.menu_widget ul a {
  font-weight: 500;
  font-size: 16.6471px;
  line-height: 200%;
  color: #ffffff;
  text-decoration: none;
}

.menu_widget ul a:hover {
  text-decoration: revert;
  opacity: 0.8;
}

footer .social {
  display: flex;
  align-items: center;
  margin-top: 18px;
  gap: 32px;
  a {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

footer .social a:hover {
  transition: 0.5s;
  opacity: 0.65;
}

.nft-card .container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 45px;
}

.nft-card .container .nft-card-left video {
  width: 100%;
  border: 1px solid #1c2437;
  border-radius: 20px;
}

.nft-card .current .current-t {
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: #828282;
  margin-bottom: 12px;
}

.nft-card .current-block {
  display: flex;
  align-items: baseline;
  gap: 10px;
  margin-bottom: 34px;
}

.nft-card .current-block div {
  font-weight: 600;
  font-size: 30px;
  line-height: 160%;
  color: #ffffff;
}

.nft-card .provenence-item:hover {
  background: #ffffff0f;
  transition: 0.5s;
  cursor: pointer;
}

.more-collection .item_nft {
  position: relative;
  min-height: 415px;
  width: 360px;
  border: 1px solid #1c2437;
  border-radius: 19px;
  overflow: hidden;
}

.more-collection .item_nft video {
  width: 360px;
  position: absolute;
  z-index: -1;
  top: 0;
}

.connect_wallet_img img {
  width: 100%;
  object-fit: cover;
  height: 170px;
  border-radius: 10px;
}

.more-collection .nft-home-top {
  justify-content: center;
}

.more-collection .btn {
  font-size: 12px;
}

.more-collection .item_nft_left h5 {
  font-size: 14px;
}

.more-collection .item_nft_left div {
  font-weight: 500;
  font-size: 12px;
}

.widget_one .container {
  background: #121622;
  border-radius: 20px;
  padding: 46px;
  text-align: center;
  position: relative;
  height: 498px;
  overflow: hidden;
}

.heads {
  display: none;
}

.widget_one .container h3 {
  background: linear-gradient(120deg, #ffb800 20%, #6cffc1 55%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 50px;
  line-height: 120%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin: 0;
  font-family: "Syncopate", sans-serif;
  margin-bottom: 35px;
}

.widget_one .container p {
  font-size: 16px;
  line-height: 180%;
  text-align: center;
  color: #ffffff;
  max-width: 750px;
  margin: 0 auto;
}

.widget_one {
  margin-bottom: 70px;
}

.widget_one img {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.widget_one .container::after {
  content: "";
  background: url(/assets/images/widget_after.png) no-repeat;
  height: 590px;
  width: 869px;
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
}

.widget_one .container::before {
  content: "";
  background: url(/assets/images/widget_before.png) no-repeat;
  height: 590px;
  width: 869px;
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: -400px;
}

.tier-block {
  display: grid;
  grid-template-columns: 365px 365px 365px;
  gap: 20px;
  justify-content: center;
  margin-bottom: 70px;
}

.tier-item {
  border-radius: 16px;
  position: relative;
  height: 240px;
  overflow: hidden;
}

.tier-item .tier-item-top {
  display: flex;
  align-items: center;
  padding: 1px 5px;
  font-size: 12px;
  line-height: 120%;
  text-transform: uppercase;
  color: #ffffff;
  font-family: "Syncopate", sans-serif;
}

.tier-item-top img {
  margin-right: -15px;
}

.tier-item .fon-tier {
  position: absolute;
  bottom: 0;
  right: 0;
}

.tier-item-info div {
  display: flex;
  gap: 12px;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 180%;
  color: #0f1521;
}

.tier-item-info {
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(36px);
  display: flex;
  justify-content: space-between;
  margin: 0;
  position: absolute;
  width: 100%;
  bottom: 0;
}

.tier-item video {
  width: 365px;
  margin-top: -28px;
}

.tier-item-topr img {
  width: 19px;
}

.tier-item-info div {
  font-size: 14px;
  color: #fff;
}

.tier-item-topr span {
  color: #828282;
  font-size: 12px;
  margin-right: 20px;
}

.tier-item-btn a {
  border: 1px solid rgba(255, 255, 255, 0.16);
  box-sizing: border-box;
  border-radius: 12px;
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  opacity: 0.8;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
}

.tier-item-btn {
  display: grid;
  gap: 12px;
  padding: 24px;
  background: #0b0e12;
}

.tier .btn_yellow {
  opacity: 1;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}

.tier .btn_yellow button {
  width: 100%;
}

.tier-home-top {
  text-align: center;
}

.tier-home-top p {
  font-size: 16px;
  line-height: 180%;
  text-align: center;
  color: #ffffff;
  opacity: 0.7;
  max-width: 740px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 38px;
}

.quick_start .container {
  background-image: url(/assets/images/quick.png),
    url(/assets/images/quick2.png);
  background-color: #121622;
  background-repeat: no-repeat;
  background-position-x: right, left;
  background-position-y: bottom;
  border: 1px solid #1c2437;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 40px;
  text-align: center;
}

.quick_start_t {
  font-family: "Syncopate", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 128%;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 5px;
}

.quick_start_p {
  font-size: 16px;
  line-height: 180%;
  text-align: center;
  color: #ffffff;
  opacity: 0.7;
}

.quick_start_steap {
  background: #131a29;
  backdrop-filter: blur(44.1667px);
  border-radius: 17.6667px;
  max-width: 640px;
  margin: 0 auto;
  padding: 26px;
}

.quick_start-top {
  display: flex;
}

.quick_start-left img {
  width: 90px;
  margin-top: -12px;
  margin-left: -15px;
}

.quick_start_right {
  text-align: left;
}

.quick_start_right h6 {
  margin: 0;
  font-weight: 500;
  font-size: 22.0833px;
  line-height: 140%;
  color: #ffffff;
}

.quick_start_right p {
  font-size: 17.6667px;
  line-height: 180%;
  color: #ffffff;
  opacity: 0.7;
  margin: 0;
  margin-bottom: 22px;
}

.quick_start_steap1 {
  margin-bottom: 22px;
  margin-top: 50px;
}

.quick_start .btn {
  display: block;
  border: 1px solid rgba(255, 255, 255, 0.16);
  color: rgba(255, 255, 255, 0.8);
  font-weight: 400;
  text-transform: inherit;
}

.btn_steap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 22px;
}

.quick_start {
  margin-bottom: 60px;
}

.form-bottom-t {
  font-family: "Druk Text Wide", serif;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 128%;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 40px;
}

.form-bottom form {
  display: grid;
  grid-template-columns: 1fr;
  max-width: 480px;
  gap: 8px;
  margin: 0 auto;
}

.form-bottom input {
  border: 1px solid #353e51;
  font-size: 14px;
  line-height: 24px;
  padding: 12px 24px;
  background: none;
  border-radius: 8px;
  color: #fff;
}

.form-bottom textarea {
  border: 1px solid rgb(53, 62, 81);
  font-size: 14px;
  line-height: 24px;
  padding: 12px 24px;
  background: none;
  border-radius: 8px;
  color: rgb(255, 255, 255);
  height: 180px;
  margin: 0px;
}

.connect_on {
  padding: 20px;
  width: 318px;
  background: #141926;
  border: 1px solid #1c2437;
  box-sizing: border-box;
  border-radius: 14.4026px;
  position: absolute;
  z-index: 10;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  display: block;
  z-index: 101;
  color: white;
}

.btn-close_connect {
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
}

.connect_on_list {
  display: grid;
  gap: 7px;
  margin-top: 20px;
}

.connect_on {
  display: none;
}

.connect_openid .connect_on {
  display: block;
}

.connect_on_list button {
  border: 1px solid #ffffff29;
  box-sizing: border-box;
  border-radius: 49px;
  background: none;
  color: #fff;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 43px;
  font-size: 17px;
  display: flex;
}

.connect_on_list button img {
  margin-left: 10px;
}

.connect_openid .popap_bg {
  display: block;
}

.form-bottom .border-href {
  width: fit-content;
  margin-top: 20px;
}

.politick_form {
  font-weight: 300;
  font-size: 12px;
  line-height: 180%;
  color: #ffffff;
  opacity: 0.4;
  width: fit-content;
  margin: 10px auto;
}

.form-bottom {
  padding-bottom: 50px;
  background-image: url(/assets/images/form_after.png),
    url(/assets/images/form_before.png);
  background-position-x: left, 1200px;
  background-repeat: no-repeat;
  background-position-y: -150px, -60px;
}

.menu_btn_mobil {
  display: none;
}

.connect_wallet {
  text-align: center;
}

.connect_wallet p {
  margin-bottom: 40px;
  color: #828282;
  font-size: 16px;
}

.border-href:before {
  content: "";
  background: #0b0e12;
  position: absolute;
  width: calc(100% - 4px);
  height: calc(100% - 5px);
  left: 1.3px;
  border-radius: 10px;
  z-index: 1;
  padding: 1px;
  margin: 0;
}

.border-href:after {
  content: "";
  background: linear-gradient(135deg, #12bcb0 20%, #fabe0e 70%);
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  border-radius: 10px;
  z-index: 0;
  transition: 1.5s;
}

.cabinet_wallet_bottom .border-href {
  width: fit-content;
}

.connect_wallet h2 {
  margin-top: 145px;
  margin-bottom: 10px;
}

.border-href {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.border-href a {
  background: linear-gradient(135deg, #12bcb0 20%, #fabe0e 70%);
  -webkit-background-clip: text;
  font-size: 16px;
  line-height: 100%;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.03em;
  position: relative;
  z-index: 1;
  padding: 17px 59px;
  width: 100%;
}

.border-href button {
  background: linear-gradient(135deg, #12bcb0 20%, #fabe0e 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  z-index: 5;
  border: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  padding: 16px 53px;
  cursor: pointer;
}

button {
  cursor: pointer;
}

.item_nft_sale .item_nft_right {
  align-items: end;
}

.cabinet_wallet .container {
  margin-bottom: 90px;
}

.btn-cab {
  display: flex;
  align-items: center;
  color: #fff;
  text-decoration: none;
}

.btn-cab img {
  height: 50px;
  border-radius: 50%;
  margin-right: 8px;
}

.connect_wallet .container {
  margin-bottom: 150px;
}

.item_nft_sale .item_nft_left div {
  font-size: 12px;
  line-height: 100%;
  letter-spacing: 0.03em;
  color: #6cffc1;
  text-transform: capitalize;
}

.btn_remove {
  font-size: 12px;
  line-height: 100%;
  text-align: right;
  letter-spacing: 0.03em;
  color: #ffb800;
  text-decoration: none;
}

.more-collection .btn {
  font-size: 12px;
  background: linear-gradient(135deg, #12bcb0 20%, #fabe0e 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  z-index: 5;
  border: 0;
}

.item_nft_right:before {
  content: "";
  background: #0b0e12;
  position: absolute;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  left: 1px;
  border-radius: 10px;
  z-index: 0;
  z-index: 1;
  padding: 1px;
  margin: 0px;
}

.copy-block {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 160%;
  color: #ffffff;
  justify-content: center;
  gap: 10px;
}

.item_nft_right {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.item_nft_right:after {
  content: "";
  background: linear-gradient(135deg, #12bcb0 20%, #fabe0e 70%);
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  border-radius: 10px;
  z-index: 0;
  transition: 1.5s;
}

.cabinet_wallet_top {
  position: relative;
  margin-bottom: 130px;
}

.copy-block button {
  border: 0;
  background: none;
  padding: 0;
  cursor: pointer;
}

.cabinet_wallet_top_img {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -100px;
  border: 5px solid #fff;
  overflow: hidden;
  border-radius: 50%;
  height: 200px;
}

.cabinet_wallet_top_img img {
  height: 200px;
}

.cabinet_wallet_info h2 {
  background: linear-gradient(135deg, #12bcb0 20%, #fabe0e 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}

.copy-block button {
  margin-top: 7px;
}

.cabinet_wallet_bottom_a {
  font-size: 16px;
  line-height: 160%;
  display: flex;
  align-items: center;
  color: #828282;
  text-decoration: none;
}

.block-form {
  border: 1px solid #353e51;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 12px 16px;
  display: grid;
  height: 72px;
  align-items: center;
  color: #fff;
  gap: 5px;
}

input {
  outline: none;
}

.block-form input {
  background: none;
  border: 0;
  color: #fff;
}

.cabinet_wallet_bottom form {
  display: grid;
  gap: 20px;
  margin-top: 15px;
}

.cabinet_wallet_bottom {
  width: 618px;
  margin: 0 auto;
}

.item_nft_sale .item_nft_right:before {
  display: none;
}

.item_nft_sale .item_nft_right:after {
  display: none;
}

.amount-c input {
  background: #0b0e12;
  border: 1px solid #353e51;
  box-sizing: border-box;
  border-radius: 6px;
  height: 32px;
  width: 210px;
  padding-left: 15px;
  color: #fff;
}

.amount-c-block {
  position: absolute;
  right: 10px;
  top: 8px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.amount-c {
  position: relative;
}

.item_nft_content .item_nft_right {
  height: 32px;
}

form.item_nft_content {
  padding-bottom: 15px;
  height: 72px;
}

.item_nft_content {
  padding-bottom: 25px;
  height: 62px;
  align-items: end;
}

.subscide {
  position: relative;
  display: inline-flex;
  align-items: center;
  margin-left: -135px;
}

.subscide:before {
  content: "";
  background: #1c2437;
  position: absolute;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  left: 1px;
  border-radius: 10px;
  z-index: 0;
  z-index: 1;
  padding: 1px;
  margin: 0px;
  width: 124px;
  height: 38px;
}

.subscide:after {
  content: "";
  background: linear-gradient(135deg, #12bcb0 20%, #fabe0e 70%);
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  border-radius: 10px;
  z-index: 0;
  transition: 1.5s;
  width: 128px;
  height: 42px;
}

.nft-admin .nft-home-card .item_nft {
  position: relative;
  min-height: 333px;
  width: 290px;
  border: 1px solid #1c2437;
  border-radius: 19px;
  overflow: hidden;
}

.nft-admin .nft-home-card {
  gap: 15px;
}

.nft-admin .item_nft video {
  width: 290px;
  position: absolute;
  z-index: -1;
  top: 0;
}

.nft-admin .item_nft_right::after {
  display: none;
}

.nft-admin .item_nft_right::before {
  display: none;
}

.nft-admin .item_nft_right p {
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  display: flex;
  align-items: center;
  text-align: right;
  color: #828282;
}

.nft-admin .item_nft_content {
  padding-bottom: 15px;
  height: 50px;
}

.nft-admin .item_nft_right span {
  color: #fff;
  margin-left: 5px;
}

.nft-admin h2 {
  font-size: 20px;
  line-height: 100%;
  display: flex;
  align-items: center;
  gap: 11px;
  border-bottom: 1px solid #353e51;
  margin-bottom: 30px;
  padding-bottom: 7px;
}

.analytics_top h2 {
  font-size: 20px;
  line-height: 100%;
  display: flex;
  align-items: center;
  gap: 11px;
  border-bottom: 1px solid #353e51;
  margin-bottom: 0px;
  padding-bottom: 7px;
}

.setting_top h2 {
  font-size: 20px;
  line-height: 100%;
  display: flex;
  align-items: center;
  gap: 11px;
  border-bottom: 1px solid #353e51;
  margin-bottom: 30px;
  padding-bottom: 7px;
}

.analytics_setting .container {
  display: grid;
  grid-template-columns: 955px 1fr;
  gap: 15px;
}

.analytics_table table {
  font-size: 14px;
  color: #ffffff;
}

.analytics_table table thead td {
  font-weight: bold;
  font-size: 14px;
  line-height: 160%;
  display: flex;
  align-items: center;
  text-align: center;
}

.analytics_table table tr {
  display: grid;
  grid-template-columns: 59px 265px 160px 166px 150px 150px;
  border-bottom: 1px solid #353e51;
  height: 85px;
  align-items: center;
  padding-left: 20px;
}

.analytics_table table td {
  display: flex;
  align-items: center;
  justify-content: center;
}

.analytics_table table tr {
  display: grid;
  grid-template-columns: 59px 247px 160px 166px 150px 150px;
}

.analytics_setting {
  margin-bottom: 150px;
}

.analytics_setting .switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.analytics_setting .switch input {
  display: none;
}

.analytics_setting .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.analytics_setting .slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.analytics_setting input:checked + .slider {
  background-color: #ffb800;
}

.analytics_setting input:focus + .slider {
  box-shadow: 0 0 1px #ffb800;
}

.analytics_setting input:checked + .slider:before {
  -webkit-transform: translateX(12px);
  -ms-transform: translateX(12px);
  transform: translateX(12px);
}

/* Rounded sliders */

.analytics_setting .slider.round {
  border-radius: 34px;
}

.analytics_setting .slider.round:before {
  border-radius: 50%;
}

.setting_bottom_setting {
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 22px;
  padding-left: 22px;
}

.setting_bottom_item {
  border: 1px solid #353e51;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 16px;
}

.setting_bottom_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding-left: 22px;
}

.setting_bottom_item_top {
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 6px;
}

.setting_bottom_info {
  color: #828282;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  margin-top: 2px;
}

.setting .border-href {
  margin-left: 22px;
  margin-top: 30px;
}

.setting_bottom_block h6 {
  color: #828282;
  font-size: 14px;
  line-height: 160%;
  margin-left: 22px;
  margin-bottom: 15px;
}

.analytics_sum h5 {
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;
  margin-top: 15px;
  font-family: "Poppins", serif;
}

.analytics_sum {
  text-align: right;
}

.analytics_sum p {
  font-weight: bold;
  font-size: 30px;
  line-height: 160%;
  color: #fff;
  margin: 0;
}

.quick_start .btn {
  -webkit-text-fill-color: aliceblue;
}

/*@media (max-width: 1620px) {
  body {
    zoom: 0.95;
  }
}*/

@media (max-width: 1520px) {
  .container {
    width: 1200px;
  }
  .three-block .container {
    grid-template-columns: 500px 500px;
  }
  .three-block .container {
    grid-template-columns: 1fr;
    grid-template-areas: "A" "B" "C";
  }
  .three-block .three-block1 h3 {
    max-width: 600px;
  }
  .three-block .three-block1 img {
    zoom: 0.6;
    z-index: 0;
    opacity: 0.3;
  }
  .three-block .container h3 {
    z-index: 1;
    position: relative;
  }
  .nft-home-top {
    display: flex;
    flex-direction: column;
    gap: 28px;
  }
  .tier-block {
    display: grid;
    grid-template-columns: 380px 380px;
    gap: 20px;
    justify-content: center;
    margin-bottom: 70px;
  }
  .form-bottom {
    background-position-x: left, 800px;
  }
  .analytics_setting .container {
    display: grid;
    grid-template-columns: 955px;
  }
}

@media (max-width: 1200px) {
  .container {
    width: 1000px;
  }
}

@media (max-width: 1000px) {
  footer .container {
    display: grid;
    grid-template-columns: 1fr;
    text-align: center;
    gap: 30px;
  }
  .head-right-pc {
    display: none;
  }
  .head-menu-pc {
    display: none;
  }
  .analytics_setting .container {
    display: grid;
    grid-template-columns: 1fr;
  }
  .analytics_table {
    overflow-x: scroll;
  }
  .analytics {
    overflow-x: scroll;
  }
  .analytics_setting {
    overflow-x: hidden;
  }
  .container {
    width: auto;
    padding: 0 10px;
    .head-left {
      .logo {
        a {
          img {
            width: 100%;
          }
        }
      }
    }
  }
  network-selector {
    margin-left: 6px !important;
    margin-right: 6px;
  }
  .menu_btn_mobil {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .btn-cab {
    margin-bottom: 20px;
  }
  .menu_btn_mobil span {
    width: 25px;
    height: 2px;
    background: #fff;
    transition: 0.5s;
  }
  header {
    position: relative;
  }
  .form-bottom {
    background: none;
  }
  .widget_one {
    margin: 10px;
    margin-bottom: 70px;
  }
  .quick_start {
    margin: 10px;
    margin-bottom: 60px;
  }
  .widget_one .container p {
    z-index: 2;
    position: relative;
  }
  .widget_one .container::after {
    width: auto;
  }
  .widget_one img {
    width: 100%;
  }
  .widget_one .container::before {
    display: none;
  }
  .widget_one .container {
    height: 408px;
  }
  .heads {
    position: absolute;
    max-width: 100%;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    top: 0;
    padding: 20px;
    background: rgb(12 16 25);
    backdrop-filter: blur(48px);
    display: none;
    height: auto;
    z-index: 100;
  }
  .head-menu ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .heads .btn-h {
    display: block;
    text-align: center;
    margin-top: 20px;
  }
  .btn-h::before {
    position: absolute;
  }
  .head-menu ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  .heads_off {
    border-bottom: 1px solid #ffffff1f;
    margin-bottom: 25px;
    padding-bottom: 2px;
  }
  .menu_openid .heads {
    display: grid;
  }
  .menu_openid .menu_btn_mobil span:nth-last-child(3) {
    display: none;
  }
  .menu_openid .menu_btn_mobil span:nth-last-child(2) {
    transform: rotate(-45deg);
    transition: 0.5s;
  }
  .menu_openid .menu_btn_mobil span:nth-last-child(1) {
    transform: rotate(45deg);
    position: absolute;
    transition: 0.5s;
  }
  .footer-right form {
    margin: 0 auto;
  }
  footer .social {
    flex-wrap: wrap;
    margin: 18px auto;
  }
  .three-block .container p {
    z-index: 2;
    position: relative;
  }
  .nft-card .container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;
  }
  .nft-card-left {
    width: 500px;
    margin: 0 auto;
  }
  .nft-card-right {
    text-align: center;
  }
  .nft-card .current-block {
    justify-content: center;
  }
  .provenence {
    margin-top: 50px;
    max-width: 500px;
    margin: 0 auto;
    margin-top: 50px;
  }
  .nft-card .nft-card-right h3 {
    font-size: 32px !important;
  }
  .nft-card .border-href a {
    width: auto;
  }
}

@media (max-width: 800px) {
  .tier-block {
    grid-template-columns: 380px;
  }
  .widget_one .container h3 {
    font-size: 25px;
    margin-bottom: 15px;
  }
}

@media (max-width: 500px) {
  .tier-block {
    grid-template-columns: 100%;
    .tier-item {
      height: 300px;
      video {
        width: 100%;
      }
    }
  }
}

@media (max-width: 700px) {
  .three-block .container h3 {
    font-size: 20px;
  }
  .three-block2,
  .three-block3,
  .three-block1 {
    height: auto;
  }
  .three-block .border-href:after {
    z-index: 2;
  }
  .three-block .border-href:before {
    z-index: 3;
  }
  .three-block a {
    z-index: 3;
  }
  h2 {
    font-size: 26px;
  }
  .three-block .container > div:not(.special-events) img {
    zoom: 0.7;
    filter: brightness(0.5);
    opacity: 1;
  }
  .cabinet_wallet_bottom {
    width: auto;
    margin: 0 auto;
  }
  .nft-card .provenence-item {
    grid-template-columns: 1fr;
    justify-content: center;
  }
  .nft-card .summ {
    text-align: center;
  }
  .nft-card .provenence-info .t {
    overflow-wrap: anywhere;
  }
}

@media (max-width: 600px) {
  .three-block .container h3 {
    font-size: 25px;
  }
  .widget_one .container {
    height: 360px;
  }
  .quick_start .container {
    background: none;
    background-image: none;
    border: 0;
    padding: 0;
  }
  .quick_start_t {
    font-size: 30px;
  }
  .form-bottom-t {
    font-size: 30px;
  }
  .three-block .container > div:not(.special-events) img {
    zoom: 0.7;
  }
  .three-block .three-block1 img {
    zoom: 0.4;
  }
  .nft-home-btn a {
    padding: 12px;
  }
  .nft-card-left {
    width: auto;
  }
  .btn_steap {
    display: grid;
    grid-template-columns: 1fr;
    gap: 22px;
  }
}

@media (max-width: 450px) {
  .tier-block {
    grid-template-columns: 360px;
    zoom: 1;
  }
  .setting_bottom_grid {
    grid-template-columns: 1fr;
  }
  .quick_start-top {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  .three-block .three-block1 {
    padding-right: 0;
    padding-left: 25px;
  }
  .three-block .three-block2 {
    min-height: auto;
    padding-right: 0;
    padding-left: 25px;
  }
  .three-block .three-block3 {
    min-height: auto;
    padding-right: 0;
    padding-left: 25px;
  }
  .widget_one .container {
    padding-left: 25px;
    padding-right: 25px;
  }
  .widget_one .container h3 {
    font-size: 21px;
  }
  .btn {
    font-size: 12px;
    padding: 13px 12px;
  }
}

/* ДЛЯ INDEX8 */

.popap {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 10;
  display: none;
}

.change_wallet {
  padding: 20px;
  width: 318px;
  background: #141926;
  border: 1px solid #1c2437;
  box-sizing: border-box;
  border-radius: 14.4026px;
  position: absolute;
  z-index: 10;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  display: none;
}

.popap .t {
  text-align: center;
  letter-spacing: 0.03em;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  margin-top: 13px;
  margin-bottom: 20px;
}

.popap p {
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 20px;
  margin-top: 0;
}

.popap_icon {
  text-align: center;
}

.popap button {
  box-sizing: border-box;
  border-radius: 10px;
  height: 50px;
  width: 100%;
  font-weight: 600;
  font-size: 12px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 0.03em;
  color: #f2f2f2;
  background: none;
  outline: none;
  box-shadow: none;
  border: 1px solid #353e51;
}

.change_wallet_close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.popap_input .t {
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  color: rgba(255, 255, 255, 0.5);
  margin: 0;
  text-align: left;
  margin-bottom: 5px;
}

.popap_input input {
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.03em;
  color: #ffffff;
  background: none;
  border: 0;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.03em;
}

.popap_bg {
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  height: 100%;
  width: 100%;
  display: none;
  z-index: 100;
  opacity: 0.8;
}

button {
  cursor: pointer;
}

button:hover {
  filter: opacity(0.5);
  transition: 0.5s;
}

.change_openid .change_wallet {
  display: block;
}

.change_openid .popap {
  display: block;
}

.change_openid .popap_bg {
  display: block;
}

.request_openid .popap {
  display: block;
}

.request_openid .popap_bg {
  display: block;
}

.request_pending {
  display: none;
}

.request_openid .request_pending {
  padding: 20px;
  width: 318px;
  background: #141926;
  border: 1px solid #1c2437;
  box-sizing: border-box;
  border-radius: 14.4026px;
  position: absolute;
  z-index: 10;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  display: block;
}

.request_openid .request_close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

@keyframes wait_animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.wait_animation img {
  animation: wait_animation 1s infinite;
}

.wait {
  display: none;
}

.wait_openid .popap {
  display: block;
}

.wait .t {
  margin-bottom: 0;
  text-align: center;
  letter-spacing: 0.03em;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  margin-top: 13px;
  margin-bottom: 20px;
}

.wait_openid .popap_bg {
  display: block;
}

.wait_openid .wait {
  padding: 20px;
  width: 318px;
  background: #141926;
  border: 1px solid #1c2437;
  box-sizing: border-box;
  border-radius: 14.4026px;
  position: fixed;
  left: 50%;
  top: 50%;
  padding-top: 60px;
  padding-bottom: 60px;
  transform: translateY(-50%) translateX(-50%);
  display: block;
  z-index: 1010;
  color: white;
}

.navigation {
  margin-top: 20px;
  margin-bottom: 20px;
}

.navigation ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 6px;
}

.navigation ul li a {
  background: #151b29;
  border: 1px solid #353e51;
  box-sizing: border-box;
  border-radius: 8px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  transition: 0.5s;
}

.navigation ul li a:hover {
  transition: 0.5s;
  background: #353e51;
}

.navigation ul li a {
  width: 32px;
  height: 32px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  border-radius: 9px;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 8px;
}

.inverted_prev img {
  transform: rotate(180deg);
}

.--copy {
  &:after {
    content: "✔ Copied";
    display: block;
    font-size: 14px;
    line-height: 20px;
    padding: 8px 12px;
    color: #ffffff;
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.16),
        rgba(255, 255, 255, 0.16)
      ),
      #1c253a;
    border-radius: 8px;
    position: absolute;
    opacity: 0;
    visibility: hidden;
    top: 20px;
    left: 65%;
    transform: translate(-50%, -100%);
  }
  &:focus:after {
    animation: copied 2s;
  }
}

@keyframes copied {
  0% {
    opacity: 1;
    visibility: visible;
  }
  90% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: visible;
  }
}

.cdk-overlay-container {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
  .cdk-overlay-connected-position-bounding-box {
    position: absolute;
    z-index: 10000;
    display: flex;
    flex-direction: column;
    min-width: 1px;
    min-height: 1px;
    top: 56.2344px;
    height: 400px;
    width: 150px;
    align-items: flex-start;
    justify-content: flex-start;
    .mat-menu-panel {
      background: transparent;
      border-radius: 8px;
      min-height: auto;
    }
    .mat-menu-content {
      margin: 0 !important;
      pointer-events: all;
      border: 1px solid #353e51;
      border-radius: 10px;
      background-color: rgb(11, 14, 18);
      &:not(:empty) {
        margin-top: 8px;
        margin-bottom: 8px;
        padding: 0;
      }
    }
    .mat-menu-panel .mat-menu-item {
      display: flex;
      align-items: center;
      height: auto;
      padding: 13px 15px 13px 12px;
      color: #f2f2f2;
      background-color: rgb(11, 14, 18);
      font-weight: bold;
      font-size: 14px;
      line-height: 100%;
      min-width: 164px;
      font-family: Roboto, "Helvetica Neue", sans-serif;
      &:hover {
        background: #151b29;
        filter: opacity(1);
      }
      &:first-of-type {
        border-radius: 10px 10px 0 0;
      }
      &:last-of-type {
        border-radius: 0 0 10px 10px;
      }
      img {
        width: 24px;
        height: 24px;
        object-fit: contain;
        margin-right: 10px;
        vertical-align: middle;
      }
    }
  }
  .cdk-overlay-backdrop {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    pointer-events: auto;
    -webkit-tap-highlight-color: transparent;
    transition: opacity cubic-bezier(0.25, 0.8, 0.25, 1);
    opacity: 0;
  }
  .cdk-overlay-backdrop {
    opacity: 1;
    //background: rgba(28, 37, 58, 0.5);
  }
  .cdk-overlay-transparent-backdrop {
    opacity: 0 !important;
  }
  .cdk-global-overlay-wrapper {
    display: flex;
    position: absolute;
    z-index: 1000;
    pointer-events: none;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

.dlg-light .mat-dialog-container {
  padding: 20px;
  border-radius: 8px;
  color: #051b52;
  background: #141926;
  filter: drop-shadow(0px 24px 32px rgba(59, 69, 91, 0.08));
  overflow: hidden;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
  div.text-center {
    max-width: 600px;
    margin-top: 23px;
  }
  .modal-header {
    border-bottom: 0;
  }
  .modal-body {
    padding-top: 0;
  }
}

mat-dialog-container {
  pointer-events: auto;
}

.btn-close {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  outline: none;
  box-shadow: none;
  border: none;
  padding: 0;
  margin: 0;
  line-height: 1;
  background: none;
  font-size: 24px;
  color: #353e51;
  opacity: 1;
  z-index: 1;
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &:after {
    content: "\e963";
    font-family: "icomoon", serif;
  }
  &:hover {
    color: #353e51;
  }
}

html,
body {
  height: 100vh;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-slider:not(.mat-slider-disabled).cdk-focused.mat-slider-thumb-label-showing
  .mat-slider-thumb {
  transform: scale(0.7) !important;
}

#confirm-bet-modal,
#sell-nft,
#cancel-nft,
#info,
#bet-modal,
#connect-wallet,
#finish-auction,
#choose-method-modal,
#enter-wallet-modal,
#wert-modal,
#cancel-auction {
  h3 {
    font-family: "Syncopate", serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 118%;
    text-transform: uppercase;
    color: #d9e9ff;
    margin: 0;
  }
  div.confirm-label {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 172%;
    color: #d9e9ff;
    margin-top: 16px;
  }
  .jw-modal-body {
    padding: 40px;
    background: #121922;
    border: 1px solid #535c71;
  }
  button:not(.btn-close) {
    justify-content: center;
    align-items: center;
    padding: 16px 24px;
    width: 192.5px;
    height: 56px;
    background: #ffb800;
    border-radius: 12px;
    &.cancel {
      background: transparent;
      border: 1px solid #535c71;
      color: #d9e9ff;
    }
    &[disabled] {
      opacity: 0.32;
      color: initial;
      border-color: transparent;
      cursor: default;
      &:hover {
        filter: none;
      }
    }
    &.buy {
      width: 100%;
    }
  }
  .btn-close {
    right: 25px;
    top: 25px;
  }
  .buttons {
    display: flex;
    margin-top: 40px;
    justify-content: space-between;
    margin-bottom: 16px;
    gap: 10px;
  }
  .bid-input {
    margin-top: 38px;
    div.bid-wrapper {
      border: 1px solid #535c71;
      border-radius: 12px;
      position: relative;
      display: flex;
      padding-right: 16px;
      &:not(:first-of-type) {
        margin-top: 16px;
      }
      img {
        width: 24px;
        padding: 16px 8px 16px 16px;
      }
      div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        input {
          color: #d9e9ff;
          background: transparent;
          border: none;
          caret-color: #ffb800;
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        input[type="number"] {
          -moz-appearance: textfield;
        }
        label {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.4px;
          color: #ffb800;
          cursor: text;
        }
      }
    }
    > div.min-bid {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: #d9e9ff;
      opacity: 0.48;
    }
  }
}

.mat-tooltip.normal {
  font-size: 15px;
  /*position: relative !important;
  left: -100px !important;*/
}

.bid-wrapper {
  .mat-form-field-wrapper {
    padding: 0;
  }
  .mat-form-field-flex {
    padding: 0;
    background: transparent;
  }
  .mat-form-field-appearance-fill .mat-form-field-underline::before {
    height: 0 !important;
  }
  .mat-form-field-ripple {
    display: none;
  }
  .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
    background: transparent;
  }
}

.footerTop {
  display: grid;
  grid-template-columns: 40% 1fr;
  .footerTopLeft {
    form {
      margin-top: 30px;
      gap: 16px;
      display: flex;
      input {
        border: 1px solid #535c71;
        border-radius: 12px;
        height: 56px;
        padding-left: 16px;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: #d9e9ff;
        background: #121622;
        min-width: 300px;
        box-sizing: border-box;
      }
      button {
        background: #ffb800;
        border: 0;
        border-radius: 12px;
        height: 56px;
        padding: 0 30px;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: #121922;
        font-weight: 500;
      }
    }
  }
  .footerTopRight {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .footerNav {
      padding: 0;
      list-style: none;
      display: flex;
      justify-content: end;
      gap: 32px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      text-align: center;
      a {
        color: #d9e9ff;
        opacity: 0.48;
        text-decoration: none;
        &:hover {
          opacity: 1;
          transition: 0.5s;
        }
      }
    }
    .social {
      justify-content: end;
    }
  }
}

.footerBottom {
  display: grid;
  grid-template-columns: 40% 1fr;
  align-items: end;
  margin-top: 30px;
  .footerBottom_gp {
    font-family: "Poppins", serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 172%;
    color: #535c71;
  }
  .footerBottom_nav {
    display: flex;
    gap: 45px;
    list-style: none;
    padding: 0;
    align-items: center;
    margin: 0;
    justify-content: end;
    a {
      font-family: "Poppins", serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 172%;
      text-decoration-line: underline;
      color: #65718b;
      &:hover {
        color: #d9e9ff;
        transition: 0.5s;
      }
    }
  }
}

@media screen and (max-width: 1150px) {
  .footerTop {
    grid-template-columns: 1fr;
    .footerTopLeft {
      text-align: center;
      form {
        justify-content: center;
      }
    }
    .footerTopRight {
      .footerNav {
        display: none;
      }
      .social {
        justify-content: center;
      }
    }
  }
  .footerBottom {
    display: flex;
    grid-template-columns: 40% 1fr;
    align-items: center;
    margin-top: 30px;
    flex-direction: column-reverse;
    gap: 15px;
  }
}

@media screen and (max-width: 500px) {
  .nft-home {
    .nft-filter {
      display: grid !important;
      grid-template-columns: 1fr;
      button {
        width: 100%;
      }
      input {
        width: 100%;
      }
      .nft-filter-item {
        margin: 0 !important;
        margin-bottom: 16px !important;
      }
    }
  }
  .footerTop .footerTopLeft form {
    justify-content: center;
    flex-direction: column;
  }
  .footerBottom {
    margin-top: 0;
    .footerBottom_nav {
      flex-direction: column;
      gap: 16px;
    }
  }
  footer .container {
    gap: 0;
    .social {
      gap: 25px;
    }
  }
  .nft-card-right {
    .two-sections {
      grid-template-columns: 1fr !important;
    }
  }
}

#info {
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #d9e9ff;
    text-transform: none;
    font-family: "Poppins", serif;
  }
}

.header-container {
  display: block !important;
}

.howto {
  margin: 30px 0;
  width: 100%;
  border: 1px solid #535c71;
  border-radius: 12px;
  font-size: 14px;
  line-height: 16px;
  color: #d9e9ff;
  padding: 13px;
  align-items: center;
  display: flex;
  place-content: center;
  cursor: pointer;
  img {
    opacity: 0.7;
    margin: 0 10px;
  }
}

.mat-slider-thumb-label {
  transform: rotate(45deg) !important;
  border-radius: 50% 50% 0 !important;
}

.mat-slider-thumb {
  transform: scale(0) !important;
}

.mat-slider-thumb-label-text {
  opacity: 1 !important;
}

.mat-accent .mat-slider-track-fill {
  background-color: #0cfdff !important;
}

.mat-slider-thumb-label {
  background-color: transparent !important;
  letter-spacing: 0.4px;
  border: 1px solid #d9e9ff;
  transform: rotate(0deg) !important;
  border-radius: 13px !important;
  padding: 0 8px;
  height: 22px !important;
  right: -19px !important;
  width: auto !important;
  > span {
    color: #d9e9ff !important;
    transform: rotate(0deg) !important;
  }
}

.mat-slider-thumb {
  background-color: #0cfdff !important;
}

.mat-radio-button {
  margin-right: 70px;
  &:last-child {
    margin-right: 0;
  }
}

.nft-lottery-page {
  margin-bottom: 50px;
  @media screen and (max-width: 1150px) {
    grid-template-columns: 1fr;
    max-width: 700px;
    margin: 0 auto 50px;
  }
}

.token-img {
  width: 15px;
}

@media (max-width: 700px) {
  .nft-lottery-page .item_nft .owner-data .title {
    min-width: 45px !important;
  }
}

@media (max-width: 500px) {
  .nft-lottery-page {
    .item_nft {
      .preview {
        .item_nft_img {
          width: 100% !important;
        }
      }
    }
  }
}

$map-background: transparent; // #111721 - value from figma

.map-container {
  background: #111721;
}

.map-landsale {
  height: 800px;
  background: $map-background;

  .dxm-control-buttons {
    fill: rgba(0, 240, 255, 0.12);
    background: $map-background;
  }

  .dxm-control-bar > g[stroke="none"],
  .dxm-control-buttons {
    transform: scale(0.8);
  }

  .dxm-background {
    fill: $map-background;
    stroke: #111721;
  }

  @media screen and (max-height: 750px) {
    height: 400px;
  }

  @media screen and (max-width: 700px) {
    .dxm-control-buttons {
      > :nth-child(-n + 6) {
        display: none;
      }
    }

    .dxm-control-bar > g[stroke="none"] {
      > :nth-child(-n + 5) {
        display: none;
      }
    }
  }
}

@keyframes bgPickt {
  0% {
    transform: translateY(300px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes bgPicktImg {
  0% {
    transform: translateY(30px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes bgLogo {
  0% {
    transform: rotate(20deg);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes opacityAnim {
  0% {
    opacity: 0.5;
    transform: scale(1.01);
  }

  100% {
    opacity: 0.2;
    transform: scale(1);
  }
}

@keyframes bgElepse {
  0% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(0.8);
  }
}

.modal-dialog {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.err-msg {
  background: rgba(155,0,0,0.8);
  color: white;
}
